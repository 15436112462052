import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { RichText as SitecoreRichText } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const RichText = ({ fields }) => {
  const content = (
    <div className={'cm-rich-text'}>
      <SitecoreRichText
        field={fields.richText}
        data-amid={fields.richText.id}
      />
    </div>
  );

  return fields.hasFade && fields.hasFade.value ? (
    <ScrollAnimation
      animateIn="toFadeInUp"
      animateOnce
      delay={100}
      duration={0.75}
    >
      {content}
    </ScrollAnimation>
  ) : (
    content
  );
};

RichText.propTypes = PropTypes.SitecoreItem.inject({
  richText: PropTypes.RichText,
  hasFade: PropTypes.Checkbox,
});

RichText.defaultProps = DefaultProps.SitecoreItem.inject({
  richText: DefaultProps.RichText,
  hasFade: DefaultProps.Checkbox,
});
export default RichText;
